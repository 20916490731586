/*
   Author: KY SOLUTIONS
   Email: contact@ky-solutions.fr
   Website: https://ky-solutions.fr
   Version: 1.0.1
   Created: October 2023
   File: Main Css File
*/


@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.nav-item {
   cursor: pointer;
}